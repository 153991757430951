import { Box, Tab, Tabs, Typography } from "@mui/material"
import {
	amber,
	blue,
	blueGrey,
	brown,
	cyan,
	deepOrange,
	green,
	indigo,
	lightBlue,
	orange,
	pink,
	purple,
	red,
	yellow,
} from "@mui/material/colors"
import { styled } from "@mui/material/styles"
import PageWithStatusBar from "components/PageWithStatusBar"
import { Line } from "components/plots/line"
import TSLineChart from "components/plots/LineChart"
import { subHours, subMinutes, subWeeks } from "date-fns"
import { subDays, subMonths, subYears } from "date-fns"
import {
	ElectricityData,
	HeatData,
	LogPeriod,
	useInterfaceQuery,
	useLogPeriodQuery,
	InterfaceLogDataFragment,
	PvtHeatPumpData,
	useInteractiveSessionMutation,
	OpenThermData,
	useInterfaceNameQuery,
	Role,
	useResidentInterfaceQuery,
	PvtHeatPumpSpaceHeatingCoolingState,
	PvtHeatPumpDhwState,
} from "generated/graphql"
import useHasRole from "hooks/useHasRole"
import useSize from "hooks/useSize"
import logPeriod from "localstate/logperiod"
import { useMessage } from "providers/MessageProvider"
import { useEffect, useMemo, useRef, useState } from "react"
import { Navigate, useParams } from "react-router-dom"
import { NameType, Payload, ValueType } from "recharts/types/component/DefaultTooltipContent"
import { GetHeatPumpErrors } from "pages/HeatPumpStatusPage/components/HeatPumpInfoMessage"
import { NOT_FOUND } from "settings/url"
import appendErrorMessage from "tools/appendErrorMessage"
import { Maybe } from "types"
import { useTranslation } from "react-i18next"
import { TFunction } from "i18next"

const REALTIME_LOG_INTERVAL = 1000
const REFRESH_REALTIME_MODE_INTERVAL = 1000 * 45

const StyledPlot = styled("div")(({ theme }) => ({
	marginBottom: theme.spacing(10),
	userSelect: "none",
}))

const StyledPeriod = styled("div")(({ theme }) => ({
	display: "flex",
	justifyContent: "center",
	marginBottom: theme.spacing(5),
}))

type Fields<Log> = {
	key: string
	name: string
	color: string
	get: ((log: Log) => number | null) | keyof Log
	decimals?: number
	yAxis?: "primary" | "secondary"
	booleanField?: boolean
	topThickLine?: boolean
	bottomThickLine?: boolean
	tooltipFormater?: (
		value: ValueType,
		name: NameType,
		props: Payload<ValueType, NameType>,
	) => [ValueType, NameType] | ValueType
}

function getResources(t: TFunction<["heatPumpMonitoring", "general", "heatPumpStates"]>) {
	//i18n keys are kept consistent with field keys so camel instead of pascal case
	const HeatPumpErrors = GetHeatPumpErrors()
	const MBUS_HEAT_FIELDS: Fields<HeatData>[] = [
		{
			key: "heatingEnergy",
			name: t("heatingEnergy"),
			color: orange[700],
			get: ({ heatingEnergy }) => heatingEnergy / 1000, // Wh -> kWh
		},
		{
			key: "coolingEnergy",
			name: t("coolingEnergy"),
			color: blue[700],
			get: ({ coolingEnergy }) => coolingEnergy / 1000, // Wh -> kWh
		},
		{
			key: "heatingPower",
			color: green[700],
			name: t("heatingPower"),
			get: ({ heatingPower }) => heatingPower / 1000,
			decimals: 2,
		}, // W -> kW
		{
			key: "coolingPower",
			name: t("coolingPower"),
			color: indigo[700],
			get: ({ coolingPower }) => coolingPower / 1000,
			decimals: 2,
		}, // W -> kW
		{ key: "volume", color: pink[700], name: t("volume"), get: "volume" },
		{ key: "flow", name: t("flow"), color: purple[700], get: ({ flow }) => flow * 1000 }, // m^3/h -> l/h
		{ key: "forwardTemp", name: t("forwardTemp"), color: red[700], get: "forwardTemp" },
		{ key: "returnTemp", name: t("returnTemp"), color: brown[700], get: "returnTemp" },
	]

	const MBUS_ELECTRICITY_FIELDS: Fields<ElectricityData>[] = [
		{ key: "power", name: t("power"), color: orange[700], get: ({ power }) => power / 1000, decimals: 2 },
		{ key: "energy", name: t("energy"), color: blue[700], get: ({ energy }) => energy / 1000 },
	]

	const HEAT_PUMP_FIELDS: Fields<PvtHeatPumpData>[] = [
		{
			key: "errors",
			name: t("errors"),
			color: red[700],
			get: ({ errors }) => ((errors?.length ?? 0) > 0 ? 1 : 0),
			topThickLine: true,
		},
		{ key: "sourceInTemp", name: t("sourceInTemp"), color: orange[700], get: "sourceInTemp" },
		{ key: "sourceOutTemp", name: t("sourceOutTemp"), color: blue[700], get: "sourceOutTemp" },
		{ key: "sinkInTemp", name: t("sinkInTemp"), color: indigo[700], get: "sinkInTemp" },
		{ key: "sinkOutTemp", name: t("sinkOutTemp"), color: green[700], get: "sinkOutTemp" },
		{ key: "dhwBoilerTemp", name: t("dhwBoilerTemp"), color: pink[700], get: "dhwBoilerTemp" },
		{ key: "sourcePumpPerc", name: t("sourcePumpPerc"), color: brown[700], get: "sourcePumpPerc", yAxis: "secondary" },
		{
			key: "sinkPumpPerc",
			name: t("sinkPumpPerc"),
			color: blueGrey[700],
			get: "sinkPumpPerc",
			yAxis: "secondary",
		},
		{ key: "auxInputTemp", name: t("auxInputTemp"), color: cyan[700], get: "auxInputTemp" },
		{
			key: "scState",
			name: t("scState"),
			color: lightBlue[700],
			get: ({ spaceHeatingCoolingState }) => {
				if (spaceHeatingCoolingState == null) return null
				return spaceHeatingCoolingState === PvtHeatPumpSpaceHeatingCoolingState.ActiveCool ||
					spaceHeatingCoolingState === PvtHeatPumpSpaceHeatingCoolingState.PassiveCool ||
					spaceHeatingCoolingState === PvtHeatPumpSpaceHeatingCoolingState.CombinedCool
					? 1
					: 0
			},
			booleanField: true,
		},
		{
			key: "shState",
			name: t("shState"),
			color: deepOrange[700],
			get: ({ spaceHeatingCoolingState }) => {
				if (spaceHeatingCoolingState == null) return null
				return spaceHeatingCoolingState === PvtHeatPumpSpaceHeatingCoolingState.ActiveHeat ||
					spaceHeatingCoolingState === PvtHeatPumpSpaceHeatingCoolingState.PassiveHeat ||
					spaceHeatingCoolingState === PvtHeatPumpSpaceHeatingCoolingState.CombinedHeat ||
					spaceHeatingCoolingState === PvtHeatPumpSpaceHeatingCoolingState.BoostHeat ||
					spaceHeatingCoolingState === PvtHeatPumpSpaceHeatingCoolingState.BackupHeat
					? 1
					: 0
			},
			booleanField: true,
		},
		{
			key: "dhwState",
			name: t("dhwState"),
			color: amber[700],
			get: ({ dhwState }) => {
				if (dhwState == null) return null
				return dhwState === PvtHeatPumpDhwState.Active ||
					dhwState === PvtHeatPumpDhwState.Passive ||
					dhwState === PvtHeatPumpDhwState.Combined ||
					dhwState === PvtHeatPumpDhwState.Boost ||
					dhwState === PvtHeatPumpDhwState.Backup
					? 1
					: 0
			},
			booleanField: true,
		},
		{
			key: "compressorOn",
			name: t("compressorOn"),
			color: purple[700],
			get: ({ compressorOn }) => (compressorOn ? 1 : 0),
			bottomThickLine: true,
			tooltipFormater: (value) => (value ? [t("general:On"), t("general:Compressor")] : []),
		},
		{
			key: "hasOnOffThermostatDemand",
			name: "Thermostaat vraag",
			color: yellow[700],
			booleanField: true,
			get: ({ hasOnOffThermostatDemand }) => {
				switch (hasOnOffThermostatDemand) {
					case true:
						return 1
					case false:
						return 0
					default:
						return null
				}
			},
		},
	]

	const THERMOSTAT_OPENTHERM_FIELDS: Fields<OpenThermData>[] = [
		{ key: "chEnable", name: t("chEnable"), color: orange[700], get: "chEnable", booleanField: true },
		{ key: "coolingEnable", name: t("coolingEnable"), color: blue[700], get: "coolingEnable", booleanField: true },
		{ key: "roomTemp", name: t("roomTemp"), color: green[700], get: "roomTemp" },
		{ key: "roomSetpTemp", name: t("roomSetpTemp"), color: indigo[700], get: "roomSetpTemp" },
		{ key: "chFeedTemp", name: t("chFeedTemp"), color: pink[700], get: "chFeedTemp" },
		{ key: "chSetpTemp", name: t("chSetpTemp"), color: purple[700], get: "chSetpTemp" },
		{
			key: "modulationLevelPerc",
			name: t("modulationLevelPerc"),
			color: red[700],
			get: "modulationLevelPerc",
			yAxis: "secondary",
		},
		{
			key: "coolingPercSetp",
			name: t("coolingPerc"),
			get: "coolingPercSetp",
			color: lightBlue[700],
			yAxis: "secondary",
		},
	]
	const BOILER_OPENTHERM_FIELDS: Fields<OpenThermData>[] = [
		{ key: "chFeedTemp", name: t("boilerChFeedTemp"), color: orange[700], get: "chFeedTemp" },
		{ key: "chSetpTemp", name: t("boilerChSetpTemp"), color: blue[700], get: "chSetpTemp" },
		{
			key: "modulationLevelPerc",
			name: t("boilerModulationLevelPerc"),
			color: green[700],
			get: "modulationLevelPerc",
			yAxis: "secondary",
		},
		{ key: "chEnable", name: t("boilerChEnable"), get: "chEnable", color: indigo[700], booleanField: true },
	]

	const COP_FIELDS: Fields<InterfaceLogDataFragment>[] = [
		{ key: "heatingCOP", name: t("heatingCOP"), color: orange[700], get: "heatingCOP" },
		{ key: "coolingCOP", name: t("coolingCOP"), color: blue[700], get: "coolingCOP" },
	]

	const LOG_PERIOD_NAMES = {
		[LogPeriod.Realtime]: t("Realtime"),
		[LogPeriod.LastHour]: t("LastHour"),
		[LogPeriod.LastDay]: t("LastDay"),
		[LogPeriod.LastWeek]: t("LastWeek"),
		[LogPeriod.LastMonth]: t("LastMonth"),
		[LogPeriod.LastYear]: t("LastYear"),
	}

	function errorTooltipFormatter(
		_value: ValueType,
		_name: NameType,
		item: Payload<ValueType, NameType>,
		logs: InterfaceLogDataFragment[],
		timeData: Date[],
	) {
		const closestDate = timeData.reduce(function (prev, curr) {
			return Math.abs(curr.valueOf() - item.payload.x) < Math.abs(prev.valueOf() - item.payload.x) ? curr : prev
		})
		const errors = logs[timeData.findIndex((date) => date === closestDate)].pvtHeatPump?.errors
		if (errors == null || errors.length < 1) return []
		if (errors.length === 1) {
			return [
				<Typography key={`${closestDate}-error`} color={HeatPumpErrors[errors[0]].fatal ? "ErrorText" : "InfoText"}>
					{HeatPumpErrors[errors[0]].name}
				</Typography>,
				t("HPError"),
			]
		}
		return [
			[...errors]
				.sort((e1, e2) => e1 - e2)
				.flatMap((e) => (
					<Typography key={HeatPumpErrors[e].name} color={HeatPumpErrors[e].fatal ? "ErrorText" : "InfoText"}>
						{HeatPumpErrors[e].name}
					</Typography>
				)),
			t("HPErrors"),
		]
	}

	function stateTooltipFormatter(
		value: ValueType,
		item: Payload<ValueType, NameType>,
		logs: InterfaceLogDataFragment[],
		timeData: Date[],
		key: string,
	): ValueType | [ValueType, NameType] {
		if (value === 0) return []
		const closestDate = timeData.reduce(function (prev, curr) {
			return Math.abs(curr.valueOf() - item.payload.x) < Math.abs(prev.valueOf() - item.payload.x) ? curr : prev
		})
		const pvtHeatPumpLog = logs[timeData.findIndex((date) => date === closestDate)].pvtHeatPump
		if (!pvtHeatPumpLog) return []

		let stateName: string | undefined
		switch (key) {
			case "dhwState":
				switch (pvtHeatPumpLog.dhwState) {
					case PvtHeatPumpDhwState.Active:
						stateName = t("heatPumpStates:DHW.Active")
						break
					case PvtHeatPumpDhwState.Passive:
						stateName = t("heatPumpStates:DHW.Passive")
						break
					case PvtHeatPumpDhwState.Combined:
						stateName = t("heatPumpStates:DHW.Combined")
						break
					case PvtHeatPumpDhwState.Boost:
						stateName = t("heatPumpStates:DHW.Boost")
						break
					case PvtHeatPumpDhwState.Backup:
						stateName = t("heatPumpStates:DHW.Backup")
						break
				}
				break
			case "shState":
				switch (pvtHeatPumpLog.spaceHeatingCoolingState) {
					case PvtHeatPumpSpaceHeatingCoolingState.ActiveHeat:
						stateName = t("heatPumpStates:SH.Active")
						break
					case PvtHeatPumpSpaceHeatingCoolingState.PassiveHeat:
						stateName = t("heatPumpStates:SH.Passive")
						break
					case PvtHeatPumpSpaceHeatingCoolingState.CombinedHeat:
						stateName = t("heatPumpStates:SH.Combined")
						break
					case PvtHeatPumpSpaceHeatingCoolingState.BoostHeat:
						stateName = t("heatPumpStates:SH.Boost")
						break
					case PvtHeatPumpSpaceHeatingCoolingState.BackupHeat:
						stateName = t("heatPumpStates:SH.Backup")
						break
				}
				break
			case "scState":
				switch (pvtHeatPumpLog.spaceHeatingCoolingState) {
					case PvtHeatPumpSpaceHeatingCoolingState.ActiveCool:
						stateName = t("heatPumpStates:SC.Active")
						break
					case PvtHeatPumpSpaceHeatingCoolingState.PassiveCool:
						stateName = t("heatPumpStates:SC.Passive")
						break
					case PvtHeatPumpSpaceHeatingCoolingState.CombinedCool:
						stateName = t("heatPumpStates:SC.Combined")
						break
				}
				break
		}
		return stateName ? [stateName, t("general:OperatingMode")] : []
	}

	function getLinesForMeter<T>(
		fields: Fields<T>[],
		logs: InterfaceLogDataFragment[],
		timeData: Date[],
		field:
			| "heatingHeatMeter"
			| "sourceHeatMeter"
			| "heatPumpElectricityMeter"
			| "pvtHeatPump"
			| "thermostat"
			| "boiler",
		group: string,
	) {
		const lines: Line[] = fields.flatMap(
			({ key, name, color, get, decimals, yAxis, booleanField, topThickLine, bottomThickLine, tooltipFormater }) => {
				const lineData: (number | null)[] = logs.map((log) => {
					const meter: Maybe<T> = log[field] as unknown as Maybe<T>
					if (!meter) return null
					return typeof get === "function" ? get(meter) : meter[get] == null ? null : Number(meter[get])
				})
				if (lineData.every((val) => val == null || val === -30)) {
					//-30 is a disconnected sensor
					return []
				}
				if (key === "errors") {
					// Recharts only allows tooltipFormater to return a string or an array of strings yet returning reactNodes works just fine and is required to display newlines
					// eslint-disable-next-line @typescript-eslint/ban-ts-comment
					// @ts-ignore
					tooltipFormater = (value, name, props) => errorTooltipFormatter(value, name, props, logs, timeData)
				}
				if (key === "scState" || key === "shState" || key === "dhwState") {
					tooltipFormater = (value, _name, props) => stateTooltipFormatter(value, props, logs, timeData, key)
				}

				return {
					group,
					field: key,
					color,
					label: name,
					id: `${field}-${key}`,
					y: lineData,
					x: timeData,
					decimals,
					yAxis,
					booleanField,
					topThickLine,
					bottomThickLine,
					tooltipFormater,
				}
			},
		)
		return lines
	}

	function mBusToLines(logs?: InterfaceLogDataFragment[] | null): Line[] {
		if (!logs) return []
		const timeData = logs.map(({ timestamp }) => new Date(timestamp))

		const heatingLines = getLinesForMeter<HeatData>(
			MBUS_HEAT_FIELDS,
			logs,
			timeData,
			"heatingHeatMeter",
			t("heatingHeatMeter"),
		)
		const sourceLines = getLinesForMeter<HeatData>(
			MBUS_HEAT_FIELDS,
			logs,
			timeData,
			"sourceHeatMeter",
			t("sourceHeatMeter"),
		)
		const electricityLines = getLinesForMeter<ElectricityData>(
			MBUS_ELECTRICITY_FIELDS,
			logs,
			timeData,
			"heatPumpElectricityMeter",
			t("heatPumpElectricityMeter"),
		)
		const heatPumpLines = getLinesForMeter<PvtHeatPumpData>(
			HEAT_PUMP_FIELDS,
			logs,
			timeData,
			"pvtHeatPump",
			t("pvtHeatPump"),
		)
		const thermostatLines = getLinesForMeter<OpenThermData>(
			THERMOSTAT_OPENTHERM_FIELDS,
			logs,
			timeData,
			"thermostat",
			t("thermostat"),
		)
		const boilerLines = getLinesForMeter<OpenThermData>(BOILER_OPENTHERM_FIELDS, logs, timeData, "boiler", t("Boiler"))

		const copLines = COP_FIELDS.flatMap(({ key, name, color, get, decimals }) => {
			const lineData = logs.map((log) => {
				return typeof get === "function" ? get(log) : log[get] == null ? null : Number(log[get])
			})
			if (lineData.every((val) => val == null)) {
				return []
			}
			return {
				field: key,
				label: name,
				color,
				id: `${key}`,
				y: lineData,
				x: timeData,
				decimals: decimals,
			}
		})

		return [
			...heatingLines,
			...sourceLines,
			...electricityLines,
			...heatPumpLines,
			...copLines,
			...thermostatLines,
			...boilerLines,
		]
	}

	function getFromUntil(period: LogPeriod): { from: string; until: string } {
		const until = new Date()
		switch (period) {
			case LogPeriod.LastHour:
				return { from: subHours(until, 1).toISOString(), until: until.toISOString() }
			case LogPeriod.LastDay:
				return { from: subDays(until, 1).toISOString(), until: until.toISOString() }
			case LogPeriod.LastWeek:
				return { from: subWeeks(until, 1).toISOString(), until: until.toISOString() }
			case LogPeriod.LastMonth:
				return { from: subMonths(until, 1).toISOString(), until: until.toISOString() }
			case LogPeriod.LastYear:
				return { from: subYears(until, 1).toISOString(), until: until.toISOString() }
			case LogPeriod.Realtime:
				return { from: subMinutes(until, 5).toISOString(), until: until.toISOString() }
		}
	}

	function useGetInterfaceData(interfaceId: string, from: string, expert: boolean, until?: string) {
		const internalData = useInterfaceQuery({
			variables: { interfaceId, from, until },
			skip: !expert,
		})
		const externalData = useResidentInterfaceQuery({
			variables: { interfaceId, from, until },
			skip: expert,
		})
		if (expert) return internalData
		return externalData
	}
	return {
		LOG_PERIOD_NAMES,
		mBusToLines,
		getFromUntil,
		useGetInterfaceData,
	}
}

export default function HeatPumpMonitoringPage() {
	const { t, i18n } = useTranslation(["heatPumpMonitoring", "general", "heatPumpErrors"])
	const { LOG_PERIOD_NAMES, mBusToLines, getFromUntil, useGetInterfaceData } = useMemo(
		() => getResources(t),
		[i18n.language],
	)
	const { interfaceId } = useParams<"interfaceId">()
	if (!interfaceId) return <Navigate to={`/${NOT_FOUND}`} /> // should never happen, but just in case, navigate to not found page

	const smallScreen = useSize("down", "sm")
	const isAdmin = useHasRole([Role.Admin])
	const isInternal = useHasRole([Role.Admin, Role.Mechanic])
	const isExpert = useHasRole([Role.Admin, Role.Mechanic, Role.Expert])
	const { data: logPeriodData } = useLogPeriodQuery()
	const period: LogPeriod = logPeriodData?.logPeriod ?? LogPeriod.LastWeek
	const [customRange, setCustomRange] = useState<{ from: string; until: string } | null>(null)
	const { from } = useMemo(() => getFromUntil(period), [period])

	const { loading, error, data, startPolling, stopPolling } = useGetInterfaceData(
		interfaceId,
		customRange?.from ?? from,
		isExpert,
		customRange?.until, // if until is not given, it will default to current time
	)

	const [startInteractiveSession] = useInteractiveSessionMutation({ variables: { interfaceId } })
	const { data: interfaceNameQueryData } = useInterfaceNameQuery({ variables: { interfaceId } })
	const interval = useRef<number>()
	const message = useMessage()

	useEffect(() => {
		if (interval.current) {
			clearInterval(interval.current)
		}
		if (period === LogPeriod.Realtime) {
			interval.current = setInterval(() => {
				startInteractiveSession().catch((err) => {
					message.error(appendErrorMessage(t("Errors.RTSessionCouldNotBeExtended"), err))
				})
			}, REFRESH_REALTIME_MODE_INTERVAL)
			startInteractiveSession()
				.then(() => {
					message.success(t("RTSessionStarted"))
					startPolling(REALTIME_LOG_INTERVAL)
				})
				.catch((err: Error) => {
					if (err?.message?.includes("unknown method")) {
						message.error(t("Errors.InterfaceDoesNotSupportRT"))
						return
					}
					message.error(appendErrorMessage(t("Errors.RTSessionCouldNotBeStarted"), err))
				})
		} else {
			stopPolling()
		}
		return () => {
			if (interval.current) clearInterval(interval.current)
		}
	}, [period])

	const lines = mBusToLines(data?.interface?.logs.data)
	const refetchForDates = (from: Date, until: Date) =>
		setCustomRange({ from: from.toISOString(), until: until.toISOString() })
	const zoomOut = () => setCustomRange(null)

	const tempLines = lines.filter(
		({ field }) =>
			field === "forwardTemp" ||
			field === "returnTemp" ||
			field === "sourceOutTemp" ||
			field === "sourceInTemp" ||
			field === "sinkInTemp" ||
			field === "sinkOutTemp" ||
			field === "dhwBoilerTemp" ||
			field === "auxInputTemp" ||
			field === "sinkPumpPerc" ||
			field === "sourcePumpPerc" ||
			field === "compressorOn" ||
			field === "dhwState" ||
			field === "scState" ||
			field === "shState" ||
			field === "errors" ||
			field === "hasOnOffThermostatDemand",
	)
	const openthermLines = lines.filter(
		({ field }) =>
			field === "roomTemp" ||
			field === "roomSetpTemp" ||
			field === "chSetpTemp" ||
			field === "chFeedTemp" ||
			field === "modulationLevelPerc" ||
			field === "chEnable" ||
			field === "coolingEnable" ||
			field === "coolingPercSetp",
	)
	const powerLines = lines.filter(
		({ field }) => field === "heatingPower" || field === "coolingPower" || field === "power",
	)
	const energyLines = lines.filter(
		({ field }) => field === "heatingEnergy" || field === "coolingEnergy" || field === "energy",
	)
	const copLines = lines.filter(({ field }) => field === "heatingCOP" || field === "coolingCOP")
	const flowLines = lines.filter(({ field }) => field === "flow")
	return (
		<PageWithStatusBar
			backButton
			noPadding={smallScreen}
			navigationName="monitoring"
			statusFromNetwork
			interfaceId={interfaceId}
		>
			<Typography
				paddingTop={smallScreen ? 2 : 0}
				paddingLeft={1}
				paddingRight={1}
				variant={smallScreen ? "h4" : "h3"}
				align="center"
			>
				{t("MonitoringOf")}{" "}
				{interfaceNameQueryData?.interface?.name ? interfaceNameQueryData?.interface?.name : interfaceId}
			</Typography>
			<StyledPeriod>
				<Box display="flex" width="100%" justifyContent="center">
					<Tabs value={LOG_PERIOD_NAMES[period]} variant="scrollable" scrollButtons allowScrollButtonsMobile>
						{[
							LogPeriod.Realtime,
							LogPeriod.LastHour,
							LogPeriod.LastDay,
							LogPeriod.LastWeek,
							LogPeriod.LastMonth,
							LogPeriod.LastYear,
						].map((lp) => {
							if (!isInternal && lp === LogPeriod.Realtime) return null
							return (
								<Tab
									key={lp}
									value={LOG_PERIOD_NAMES[lp]}
									label={LOG_PERIOD_NAMES[lp]}
									onClick={() => {
										logPeriod(lp)
										setCustomRange(null)
									}}
									disabled={period !== lp && loading}
								/>
							)
						})}
					</Tabs>
				</Box>
			</StyledPeriod>
			<Box marginLeft={smallScreen ? 1 : 0}>
				{error && <Typography>{t("general:Errors.FetchingHeatPumps")}</Typography>}
				{!data && loading && <Typography>{t("FetchingHP")}</Typography>}
			</Box>
			{data && (
				<>
					{tempLines.length > 0 && (
						<StyledPlot>
							<Typography variant="h4" align="center">
								{t("general:HeatPump")}
							</Typography>
							<TSLineChart
								lines={tempLines}
								xlabel={t("date")}
								ylabel="°C"
								ylabelSecondary="%"
								animation={period !== LogPeriod.Realtime}
								onDateRangeChanged={refetchForDates}
								onZoomOut={zoomOut}
							/>
						</StyledPlot>
					)}
					{openthermLines.length > 0 && (
						<StyledPlot>
							<Typography variant="h4" align="center">
								{t("general:OpenTherm")}
							</Typography>
							<TSLineChart
								lines={openthermLines}
								xlabel={t("date")}
								ylabel="°C"
								ylabelSecondary="%"
								animation={period !== LogPeriod.Realtime}
								onDateRangeChanged={refetchForDates}
								onZoomOut={zoomOut}
							/>
						</StyledPlot>
					)}
					{isAdmin && powerLines.length > 0 && (
						<StyledPlot>
							<Typography variant="h4" align="center">
								{t("Power")}
							</Typography>
							<TSLineChart
								lines={powerLines}
								xlabel={t("date")}
								ylabel={t("kW")}
								animation={period !== LogPeriod.Realtime}
							/>
						</StyledPlot>
					)}
					{energyLines.length > 0 && (
						<StyledPlot>
							<Typography variant="h4" align="center">
								{t("Energy")}
							</Typography>
							<TSLineChart
								lines={energyLines}
								xlabel={t("date")}
								ylabel={t("kWh")}
								animation={period !== LogPeriod.Realtime}
							/>
						</StyledPlot>
					)}
					{isAdmin && flowLines.length > 0 && (
						<StyledPlot>
							<Typography variant="h4" align="center">
								{t("Flow")}
							</Typography>
							<TSLineChart
								lines={flowLines}
								xlabel={t("date")}
								ylabel={t("l/h")}
								animation={period !== LogPeriod.Realtime}
							/>
						</StyledPlot>
					)}
					{isAdmin && copLines.length > 0 && (
						<StyledPlot>
							<Typography variant="h4" align="center">
								{t("COP")}
							</Typography>
							{data.interface?.logs.heatingCOPavg && (
								<Typography align="center">
									{t("AvgCOPHeatingForPeriod")}: {data.interface.logs.heatingCOPavg.toFixed(1)}
								</Typography>
							)}
							{data.interface?.logs.coolingCOPavg && (
								<Typography align="center">
									{t("AvgCOPCoolingForPeriod")}: {data.interface.logs.coolingCOPavg.toFixed(1)}
								</Typography>
							)}
							<TSLineChart
								lines={copLines}
								xlabel={t("date")}
								ylabel=""
								yrange={[0, 10]}
								animation={period !== LogPeriod.Realtime}
							/>
						</StyledPlot>
					)}
				</>
			)}
		</PageWithStatusBar>
	)
}
